






















import { Component } from 'vue-property-decorator'
import BasePageIndexElastic from '../../../base/components/homepage/PageIndexElastic.vue'
import { Layout } from '~base/common/types'
import Sidebar from '~components/sidebar/Sidebar.vue'
import Layout1 from '~components/content/layouts/Layout-1.vue'
import Layout2 from '~components/content/layouts/Layout-2.vue'
import Layout4 from '~components/content/layouts/Layout-4.vue'

@Component<PageIndexElastic & BasePageIndexElastic>({
  components: {
    Sidebar,
  },
})
export default class PageIndexElastic extends BasePageIndexElastic {
  get postLayouts(): Layout[] {
    return [
      { component: Layout1, items: 1 },
      { component: Layout2, items: 2 },
      { component: Layout4, items: 2 },
      { component: Layout1, items: 1 },
      { component: Layout4, items: 2 },
      { component: Layout2, items: 2 },
    ]
  }
}
